/* GT America Standard */

@font-face {
  font-family: 'GT America';
  font-weight: 400;
  font-style: normal;
  font-stretch: normal;
  src: local('GT-America-Standard-Regular'),
    url('../fonts/GT-America-Standard-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'GT America';
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  src: local('GTAmerica-Medium'),
    url('../fonts/GTAmerica-Medium.otf') format('opentype');
}

/* GT America Condensed */

@font-face {
  font-family: 'GT America Condensed';
  font-weight: 500;
  font-style: normal;
  font-stretch: normal;
  src: local('GT-America-Condensed-Medium'),
    url('../fonts/GT-America-Condensed-Medium.otf') format('opentype');
}

@font-face {
  font-family: 'GT America Condensed';
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  src: local('GT-America-Condensed-Bold'),
    url('../fonts/GT-America-Condensed-Bold.otf') format('opentype');
}
